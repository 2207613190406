<!-- Displays the details for a single collection group, along with its associated collections. -->
<template>
  <div class="collection-group-container">
    <div v-if="targetCollectionGroup && !loading" class="media-info">
      <div class="collection-group-image" :style="`background-image: url(${targetCollectionGroup.image})`" />
      <div class="info">
        <Typography variant="pc-title-48 mobile-title-28">{{ targetCollectionGroup.name }}</Typography>
        <p class="collection-group-description">{{ targetCollectionGroup.description }}</p>

        <div v-if="targetCollectionGroup.socials" class="social">
          <div class="icons">
            <a
              v-for="(social, index) in targetCollectionGroup.socials"
              :key="`social-${index}`"
              :href="`${social.link}`"
              target="_blank"
            >
              <v-icon size="20">{{ `mdi-${social.icon}` }}</v-icon>
            </a>
          </div>
        </div>

        <table class="data-table">
          <tr>
            <td>Pieces / Variants</td>
            <td>{{ (Object.entries(primaryTypesFormatted).length || '-') + ' / ' + (targetCollectionGroup.numTypes || '-') }}</td>
          </tr>
          <tr>
            <td>Pieces on Market</td>
            <td>{{ targetCollectionGroup.onMarket || '-' }}</td>
          </tr>
          <tr>
            <td>Lowest Ask</td>
            <td>
              <template v-if="targetCollectionGroup.lowest">
                <CryptoFiat :crypto="targetCollectionGroup.lowest" />
              </template>
              <span v-else>-</span>
            </td>
          </tr>
          <tr>
            <td>Highest Sale</td>
            <td>
              <template v-if="targetCollectionGroup.highestSale">
                <CryptoFiat :crypto="targetCollectionGroup.highestSale" />
              </template>
              <span v-else>-</span>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <Typography
      v-if="Object.entries(primaryTypesFormatted).length"
      variant="pc-title-48 mobile-title-28 collections-title"
    >
      Works
    </Typography>
    <div v-if="!loading" class="collection-gallery">
      <div
        v-for="[key, value] of Object.entries(primaryTypesFormatted)"
        :key="`type-${key}`"
      >
        <TokenCard
          :tokenData="value"
          mode="collection-group"
          square
          @view="viewPrimaryType(value.tokenAddress, key)"
        />
        <DataTable
          class="desktop-only"
          :rowData="Object.values(value.secondary)"
          :maxRows="3"
        />
        <Typography variant="mobile-sub-12 mobile-only center">
          {{ marketDataByTypePrimary(value.tokenAddress, key, true).onMarket }} on Market
        </Typography>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
export default {
  async created () {
    this.setLoading(true)
    await this.getCollectionGroup(this.$route.params.id)
    await this.getTargetGroupTypes(this.$route.params.id)
    this.setLoading(false)
  },
  computed: {
    ...mapGetters([
      'marketData',
      'marketDataByType',
      'marketDataByTypePrimary',
      'targetCollectionGroup',
      'targetGroupCollections',
      'targetGroupTypes',
      'loading'
    ]),
    collectionsFormatted () {
      const formatted = []
      if (!this.targetGroupCollections) return formatted
      this.targetGroupCollections.forEach((collection) => {
        const collectionFormatted = { ...collection }
        collectionFormatted.title = collection.name
        formatted.push(collectionFormatted)
      })
      return formatted
    },

    /**
     * Formats the primary types associated with this collection group for display in gallery.
     */
    primaryTypesFormatted () {
      const formatted = {}
      if (!this.targetGroupTypes) return formatted
      this.targetGroupTypes.forEach((type) => {
        if (!formatted[type.typePrimary.id]) {
          formatted[type.typePrimary.id] = {
            tokenAddress: type.collection,
            id: type.id,
            title: `${type.series && type.series.name !== type.typePrimary.name ? `${type.series.name} - ` : ''}${type.typePrimary.name}`,
            typePrimary: type.typePrimary,
            mode: type.typePrimary.mode || null,
            secondary: {}
          }
        }
        if (formatted[type.typePrimary.id].mode !== 'one-of-ones') {
          formatted[type.typePrimary.id].secondary[type.typeSecondary.id] = {
            key: type.typeSecondary.name,
            value: (this.marketDataByType(type.collection, type.id)).onMarket || '-',
            url: `/market/${type.collection}/${type.typePrimary.id}/${type.typeSecondary.id}`
          }
        } else {
          formatted[type.typePrimary.id].secondary.all = {
            key: `1-of-1 Artworks (${this.marketDataByTypePrimary(type.collection, type.typePrimary.id).length || '-'})`,
            value: this.marketDataByTypePrimary(type.collection, type.typePrimary.id, true).onMarket || '-',
            url: `/market/${type.collection}/${type.typePrimary.id}/all`
          }
        }
      })
      return formatted
    }
  },
  methods: {
    ...mapActions([
      'getCollectionGroup',
      'getTargetGroupCollections',
      'getTargetGroupTypes'
    ]),
    ...mapMutations([
      'setLoading'
    ]),
    primaryTypeLink (collection, primary) {
      return `/market/${collection}/${primary}/all`
    },
    viewPrimaryType (collection, primary) {
      this.$router.push(`/market/${collection}/${primary}/all`)
    },
    viewCollection (collection) {
      this.$router.push(`/market/${collection}/all/all`)
    }
  }
}
</script>

<style lang="scss" scoped>
.collection-group-container {
  margin-top: 0;
}

.collection-group-name {
  @include mobile {
    margin-top: 40px;
    margin-bottom: 24px;
  }
}

.works-title {
  margin-bottom: $space-m;
}

.collection-group-image {
  @include bg-cover-center;
  width: 100%;
  min-height: 320px;
  margin: 0 auto;

  @include desktop {
    height: 500px;
    width: 500px;
  }

  @include mobile {
    padding-top: 100%;
  }
}

.collection-group-description {
  font-family: Untitled Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 1.6em;
  font-size: 14px;

  @include desktop {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

.collections-title {
  margin-bottom: 16px;
  margin-top: 30px;

  @include desktop {
    margin: 68px 0 30px;
  }
}

.collection-gallery {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(auto, auto);
  gap: 40px 20px;
  padding-bottom: $space-xl;

  @include mobile {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

}

</style>
